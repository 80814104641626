@keyframes blink{
    50% {
        opacity: 0;
    }
}

.blinking-element{
    animation: blink 2.3s linear infinite;
}

body {
    background-image: url('../public/images/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: #EFEFEF;
    /* Set the background color to a light grey */
    font-family: 'Montserrat', sans-serif;
    /* Use the Montserrat font for all text on the page */
    color: #333;
    /* Set the text color to a dark grey */
    min-height: 100vh;
    /* Set the minimum height of the body to the full height of the viewport */
}

main {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.32);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(5.5px);
    border: 1px solid rgba(255, 255, 255, 0.51);

    margin-top: 50px;
    margin-bottom: 50px;

    font-size: 18px;
    /* Increase the font size of the portfolio items */
    padding: 20px;
}

.logo{
    height:5vh;
    width: auto;
 }
 

.title {
    font-family: "Montserrat";
    /* Make the title bold */
    text-align: center;
    /* Center the title */
    margin: 0;
    /* Remove the default margin from the title */
    padding: 20px;
    /* Add some padding to the title */
}

.home{
    font-size: 10vh;
}
.other{
    font-size: 5vh;
    /* Increase the font size of the title */
    font-weight: bold;
}

/* Style the active nav link with a bottom border */
.nav-link.active {
    border-bottom: 2px solid #181a1b;
    /* Add a 3px bottom border with a dark blue color */
}

.icon {
    height: 32px;
    width: auto
}

.card {
    margin-bottom: 20px;
}


.grid-container{
	display:grid;
	grid-template-columns: 45% 55%;
	grid-row-gap:30px;
	margin-bottom:50px;
}
.project-list{
	width:80%;
	margin-left:auto;
	margin-right:auto;
}
.project{
    box-shadow: 4px 4px 2px #808080;
	background-color: white;
	text-align: center;
    color:#0C2A3C;
	height:30vh;	
	width:auto;
}

.project img{ 
    margin-left: 0;
	height:30vh;
	width:auto;
}
.col{
    margin: 0;
}

.profile-image {
    float: right;
    width: auto;
    height: 50vh;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.aboutBtn{
    margin:20px;
    font-size:5vh;
    background-color:transparent;
    border: 5px solid transparent;
    color:black;
    padding: 20px;
    border-radius: 20px;
    
}
.aboutBtn:hover{
    background-color:black;
    border: 5px solid  black;
    color:#CACDD5;
    padding: 20px;
    float: right;
    border-radius: 20px;
    transition-duration: 0.6s;
}